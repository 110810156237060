<template>
  <v-container
    v-if="dataStudio"
    class="backgroundImage"
    :style="{ 'background-image': `url(${dataStudio.backgroundSrc})` }"
    fluid
  >
    <progress-circular v-if="loading" />
    <template v-else>
      <v-row v-if="expired" align="center" justify="center">
        <v-col cols="12" sm="10" md="7">
          <transition appear name="dialog">
            <v-card>
              <v-card-text
                :class="{ headline: $vuetify.breakpoint.xs }"
                style="color: white"
                class="display-1 text-center font-weight-bold mb-1"
                v-text="dataStudio.studioName"
              />
              <v-card-text
                style="color: white; white-space: pre-line"
                :class="{ 'body-2': $vuetify.breakpoint.xs }"
                class="headline font-weight-black text-justify "
                v-text="dataStudio.text"
              />
              <div
                v-if="dataStudio.childNodes && dataStudio.childNodes.length"
                class="d-flex flex-wrap justify-space-around"
              >
                <v-btn
                  class="mb-3"
                  :x-small="$vuetify.breakpoint.xs"
                  :loading="loading"
                  dark
                  color="orange"
                  @click="createStudioUser(dataStudio.id)"
                  >{{ dataStudio.additionalStudioName || dataStudio.studioName }}</v-btn
                >
                <v-btn
                  v-for="item in dataStudio.childNodes"
                  :key="item.id"
                  class="mb-3"
                  :x-small="$vuetify.breakpoint.xs"
                  :loading="loading"
                  dark
                  color="orange"
                  @click="createStudioUser(item.id)"
                  >{{ item.studioName }}</v-btn
                >
              </div>
              <v-card-actions v-else>
                <v-spacer />
                <v-btn
                  :loading="loading"
                  dark
                  color="orange"
                  @click="createStudioUser(dataStudio.id)"
                  >Погрузиться</v-btn
                >
              </v-card-actions>
              <v-btn color="red" icon @click="$router.push('/studios')">
                <v-icon large>mdi-chevron-left</v-icon>
              </v-btn>
            </v-card>
          </transition>
        </v-col>
      </v-row>
      <date-release v-else :data-studio="dataStudio" @openStudio="expired = true" />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StudioHome',
  metaInfo() {
    if (this.dataStudio) {
      return {
        title: `${this.dataStudio.studioName} | Описание психологической студии`,
        titleTemplate: null,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `${this.metaInfoDescription}`
          }
        ]
      }
    }
  },
  components: {
    ProgressCircular: () => import('../../components/shared/ProgressCircular'),
    DateRelease: () => import('../../components/shared/DateRelease')
  },
  data: () => ({
    expired: true
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.shared.loading
    }),
    ...mapGetters(['admin']),
    titleStudios() {
      return this.$route.params.titleStudios
    },
    dataStudio() {
      return this.$store.getters.dataStudio(this.titleStudios)
    },
    metaInfoDescription() {
      return this.dataStudio.shortDescription
        ? this.dataStudio.shortDescription
        : 'Студии - это игровое пространство для самостоятельного решения психологических проблем'
    }
  },
  created() {
    if (!this.dataStudio) {
      this.$store.dispatch('getListStudios', 'studios').then(() => {
        if (
          this.dataStudio.studioOpeningDate &&
          +new Date(this.dataStudio.studioOpeningDate) - +new Date() >= 0
        ) {
          this.expired = false
        }
      })
    } else {
      if (
        this.dataStudio.studioOpeningDate &&
        +new Date(this.dataStudio.studioOpeningDate) - +new Date() >= 0
      ) {
        this.expired = false
      }
    }
  },
  methods: {
    createStudioUser(id) {
      let path
      if (this.dataStudio.childNodes && this.dataStudio.childNodes.length) {
        path = `/studio/${id}/1?parent=${this.dataStudio.id}`
      } else {
        path = `/studio/${id}/1`
      }
      if (!this.user[id]) {
        this.$store
          .dispatch('createStudioUser', {
            titleStudios: id,
            typeExercise: 'studios'
          })
          .then(title => {
            const label = {}
            label[title] = true
            this.$store.dispatch('changeDataUser', { ...label })
          })
          .then(() => this.$router.push(path))
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.backgroundImage{
  background-size cover
  height 100%
  background-attachment: fixed
}
.v-card{
  background: rgba(255, 143, 0, 0.7) !important;
  padding: 10px
  color: #fff
}
.dialog-enter, .dialog-leave-to  {
  opacity: 0;
}
.dialog-enter-active, .dialog-leave-active  {
  transition: opacity 1s;
}
</style>
